/*!
Theme Name: Resident360
Theme URI: 
Description: 
Author: Resident360
Author URI:
Version: 0.1
*/

@import "scss/scss_output.css";
@import "simple-lightbox.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

#mainNav {
  & > .menu-item {
    @apply relative;
    ul {
      @apply lg:absolute top-full right-0 bg-darker/90 min-w-max py-3 flex-col gap-1 hidden;
      & > .menu-item {
        & > a {
          @apply text-white px-6 py-1 text-base block hover:text-primary transition-all text-center lg:text-left;
        }
      }
    }
    &:hover {
      ul {
        @apply flex animate-fadeIn;
      }
    }
    & > a {
      @apply text-white lg:text-white hover:text-primary transition-all duration-500 uppercase px-1 py-2 block tracking-widest leading-none text-center relative;
    }
    &::after {
      content: "";
      @apply absolute bottom-0 left-1/2 w-0 h-[2px] -translate-x-1/2 transition-all bg-primary duration-500 hidden lg:block;
    }
    &:hover {
      &::after {
        @apply w-full;
      }
    }
    &.menu-item-has-children {
      & > a::after {
        content: "";
        @apply absolute bottom-0 left-1/2 w-0 h-[2px] -translate-x-1/2 transition-all bg-primary duration-500 lg:hidden;
      }
      &:hover {
        & > a::after {
          @apply w-full;
        }
      }
    }
  }
  & > .menu-item.button {
    @apply block;
    a {
      @apply lg:bg-primary hover:bg-dark text-white hover:text-primary lg:hover:text-white transition-all px-4 lg:px-5 py-3 lg:py-4 after:hidden duration-500;
    }
  }
}

.header_modal_menu {
  ul {
    @apply flex flex-col gap-5 lg:min-h-min justify-center text-center lg:text-left;
  }
  li {
    @apply relative -left-[200%];
    a {
      @apply text-2xl lg:text-3xl font-serif uppercase text-medium hover:text-primary hover:pl-4 transition-all duration-300 animate-fadeInLeft relative block opacity-0 leading-none;
    }
    &:nth-child(2) a {
      animation-delay: 0.1s;
    }
    &:nth-child(3) a {
      animation-delay: 0.2s;
    }
    &:nth-child(4) a {
      animation-delay: 0.3s;
    }
    &:nth-child(5) a {
      animation-delay: 0.4s;
    }
    &:nth-child(6) a {
      animation-delay: 0.5s;
    }
    &:nth-child(7) a {
      animation-delay: 0.6s;
    }
    &:nth-child(8) a {
      animation-delay: 0.7s;
    }
    &.menu-item.button {
      a {
        @apply inline-block border border-medium hover:text-white hover:bg-primary transition-all px-4 lg:px-5 py-2 lg:py-3 duration-500 m-0;
      }
    }
  }
}

@layer components {
  html {
    -webkit-font-smoothing: antialiased;
  }
  .btn-outline {
    @apply bg-transparent text-body hover:bg-accent  hover:text-white px-4 lg:px-5 py-3 lg:py-4 transition-all border border-body hover:border-primary block max-w-fit leading-none text-lg tracking-widest uppercase;
  }
  .btn-primary {
    @apply bg-primary hover:bg-secondary text-white hover:text-body px-4 py-3 rounded-full transition-all border border-primary hover:border-body block max-w-fit leading-none text-lg;
  }
  .py-normal {
    @apply py-14 lg:py-28;
  }
  .py-large {
    @apply py-16 lg:py-32;
  }
  .swiper-pagination {
    @apply bottom-6 lg:bottom-10 !important;
    .swiper-pagination-bullet {
      @apply bg-white w-[10px] h-[10px] mx-3 !important;
    }
    &.dark {
      .swiper-pagination-bullet {
        @apply bg-darker w-[10px] h-[10px] mx-3 !important;
      }
    }
  }
  .simple_text_inner h2,
  .parallax_section_content h2,
  .walk_score_section_content h2,
  .section-title {
    @apply font-serif text-3xl lg:text-4xl 2xl:text-5xl mb-8 lg:mb-8 leading-tight;
  }

  .header_content_inner,
  .parallax_section_content {
    h1,
    h2 {
      @apply font-serif text-5xl lg:text-[70px] leading-none tracking-wide mb-8 xl:mb-10;
    }
    p {
      @apply lg:text-lg uppercase tracking-wider mb-4;
    }
  }
  .two_column_content_section + .two_column_content_section {
    @apply lg:-mt-10;
  }

  .parallax_section_content {
    a {
      @apply bg-accent inline-block text-white uppercase px-7 py-4 text-center hover:bg-primary transition-all duration-300 text-base;
    }
    p {
      @apply max-w-[260px] mx-auto md:max-w-none md:mx-0;
    }
  }

  .testimonials_section_testimonial_item {
    h3 {
      @apply text-accent uppercase mb-5 text-lg tracking-wider;
    }

    p {
      @apply leading-relaxed;
    }

    h4 {
      @apply text-accent text-sm uppercase tracking-wider mt-4;
    }
  }
  .walk_score_section_content {
    h2 {
      @apply text-center;
    }
    ul {
      @apply border-y border-white p-8 flex flex-col items-center gap-4;

      li {
        @apply text-xl min-w-[180px];
        strong {
          @apply text-3xl font-serif mr-4;
        }
      }
    }
  }
  .modal {
    @apply animate-fadeIn fixed top-0 left-0 bottom-0 right-0 hidden [&.is-active]:block [&.is-active]:lg:flex justify-center items-center z-20;
    &:before {
      content: "";
      @apply absolute top-0 left-0 bottom-0 right-0;
    }
  }
  .modal-content ul {
    @apply flex flex-col gap-3 lg:gap-0 pl-4 ml-1 list-disc;
    li {
      @apply leading-snug lg:leading-loose md:text-base lg:text-lg marker:text-accent;
    }
  }
  .location-carousel {
    ul {
      @apply flex flex-col gap-3 lg:gap-0 list-disc list-inside;
      li {
        @apply leading-snug lg:leading-loose text-sm md:text-base marker:text-accent;
      }
    }
  }
}

.floorplans_filter {
  button {
    @apply text-accent transition-all relative pb-[3px] duration-500 uppercase tracking-widest font-medium;
    &::after {
      content: "";
      @apply absolute bottom-0 left-1/2 w-0 h-[2px] -translate-x-1/2 transition-all bg-accent duration-500;
    }
    &:hover,
    &.active {
      &::after {
        @apply w-full;
      }
    }
  }
}

.owl-dots {
  @apply flex gap-3 mt-8 justify-center lg:justify-start;
}

.owl-dot {
  @apply hover:opacity-60 transition-opacity;
  span {
    @apply w-3 h-3 bg-primary block rounded-full;
  }
  &.active {
    span {
      @apply w-3 h-3 bg-accent;
    }
  }
}
.two_column_gallery {
  @apply h-full;
  .owl-stage-outer,
  .owl-stage,
  .owl-item {
    @apply h-[400px] lg:h-full;
  }
}

.two_column_gallery .owl-dots {
  @apply justify-center -mt-10 z-10 absolute w-full left-0;
  .owl-dot {
    span {
      @apply bg-white w-4 h-4;
    }
    &.active {
      span {
        @apply bg-accent;
      }
    }
  }
}

.contact_field {
  @apply mb-6;
  input,
  select,
  textarea {
    @apply px-4 py-3 w-full rounded-none border-medium/50;
  }

  &.contact_field_submit input {
    @apply bg-white border w-full lg:w-fit px-8 border-medium/50 tracking-wider text-lg uppercase hover:bg-accent hover:text-white transition-all duration-300 hover:border-accent;
  }

  textarea {
    @apply lg:h-[345px];
  }
}
.simple_text {
  h2 {
    @apply font-serif text-3xl lg:text-4xl 2xl:text-5xl mb-8 lg:mb-8 leading-tight;
  }
  h3 {
    @apply font-sans text-xl mb-4 lg:mb-4 leading-tight font-bold;
  }
  p {
    @apply lg:text-lg;
  }
}
.simple_text_inner {
  h2,
  p {
    @apply text-center lg:text-left;
  }
  p {
    @apply lg:text-lg;
  }
  ul {
    @apply flex flex-col gap-3 lg:gap-0 pl-4 ml-1 list-disc;
    li {
      @apply leading-snug lg:leading-loose text-lg marker:text-accent;
    }
  }
}
.sl-overlay {
  @apply bg-darker;
}
.sl-wrapper .sl-navigation button,
.sl-wrapper .sl-close,
.sl-wrapper .sl-counter {
  @apply text-white !important;
}
