@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger.is-active:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #CBCBCB;
}

.hamburger-box {
  width: 40px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Minus
 */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/* Defaults */
html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer !important;
  transition: all 0.3s;
}
a:hover {
  text-decoration: none;
}

.modal_form .contact-field {
  margin: 10px 0;
  position: relative;
}

.form_field_icon {
  position: absolute;
  top: 12px;
  right: 20px;
}

.modal_form .wpcf7-response-output {
  display: none !important;
}
.modal_form .contact-field span.wpcf7-form-control-wrap {
  position: relative;
  display: block;
}
.modal_form .contact-field .wpcf7-not-valid-tip {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 50px;
  line-height: 50px;
}
.modal_form input,
.modal_form textarea,
.modal_form select {
  width: 100%;
  font-size: 16px;
  padding: 10px 15px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #e5e5e5;
  outline: none;
  transition: all 0.3s;
  margin: 0;
  border-radius: 0;
}
.modal_form input:focus {
  border-color: #2a2d33;
}
.modal_form textarea {
  height: 150px;
}
.modal_form textarea:focus {
  border-color: #2a2d33;
}
.modal_form input.wpcf7-submit {
  background: #eeaa00;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 21px;
  padding: 15px 40px;
  display: inline-block;
  line-height: 21px;
  border-radius: 4px;
  transition: all ease 0.5s;
  border: none;
}
.modal_form input.wpcf7-submit:hover {
  background: #2a2d33;
  text-decoration: none;
}
.modal_form .contact-field .ajax-loader {
  position: absolute;
  top: 18px;
  left: 8px;
}

.cover {
  background-size: cover !important;
  background-position: center !important;
}

.top_bar {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px 40px;
  font-size: 14px;
  font-weight: 500;
}

.header_logo {
  text-align: center;
}
.header_logo img {
  max-width: 200px;
}

p:last-child {
  margin: 0;
}

.header_menu ul li a i {
  width: 0px;
  overflow: hidden;
  margin: 2px 0 0 10px;
  display: inline-flex;
  transition: all 0.3s;
}
.header_menu ul li a:hover i {
  width: 13px;
}

.two_column_content_image_btn {
  @apply tracking-widest p-[48px] lg:p-[36px] bg-black/50 lg:bg-black/40 text-white block absolute top-0 right-0 w-[70px] hover:w-[100px] h-full bottom-0;
}
.two_column_content_image_btn span {
  @apply block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90 w-max;
}
.two_column_content_image_btn span i {
  @apply opacity-0 transition-all duration-500 block absolute top-[5px] right-0;
}
.two_column_content_image_btn:hover span i {
  @apply opacity-100 delay-100 -right-8;
}

.two_column_content_image_right .two_column_content_image_btn {
  right: auto;
  left: 0;
}

.contact_gallery_carousel_item {
  height: 300px;
}

.contact_gallery_section {
  background: #bf9643;
  text-align: center;
  color: #fff;
}

.contact_gallery_footer {
  padding: 30px 0;
}
.contact_gallery_footer ul li {
  display: inline-block;
  margin: 0 5px;
  font-size: 20px;
}
.contact_gallery_footer ul li a {
  color: #fff;
}

.contact_gallery_section button.owl-prev {
  margin-right: 150px;
}
.contact_gallery_section button.owl-next {
  margin-left: 150px;
}
.contact_gallery_section .owl-nav {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.contact_gallery_section .owl-nav i {
  font-size: 30px;
  transform: translateY(62px);
  display: block;
}

.owl-nav button {
  outline: none !important;
}

.testimonials_section_broder_top {
  background: url(img/top.png);
}

.testimonials_section_broder_bottom {
  background: url(img/bottom.png);
}

.testimonials_section_testimonials_carousel_el {
  position: relative;
}
.testimonials_section_testimonials_carousel_el .owl-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
.testimonials_section_testimonials_carousel_el .owl-nav button {
  background: #4d5d69 !important;
}
.testimonials_section_testimonials_carousel_el .owl-nav i {
  font-size: 40px;
}

.testimonials_section_testimonials_carousel_el .owl-item.active:nth-child(2n) {
  border-left: 1px solid rgba(255, 255, 255, 0.28) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.28) !important;
}

.footer_menu {
  display: flex;
  text-align: center;
}
.footer_menu ul {
  padding: 30px 40px;
  border-right: 1px solid #fff;
}
.footer_menu ul:last-child {
  border: none;
}
.footer_menu ul li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 5px 4px;
  display: inline-block;
}

.footer_links {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.footer_links ul li {
  display: inline-block;
  padding: 0 10px;
  border-right: 1px solid #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  margin: 5px 4px;
}
.footer_links ul li:last-child {
  border: 0;
}
.footer_links ul li a {
  color: #fff;
}

.footer_credit {
  text-align: center;
}
.footer_credit ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_credit ul li {
  display: inline-flex;
  margin: 0 8px;
  font-size: 12px;
}
.footer_credit ul li a {
  color: #8d8b8b;
}
.footer_credit ul li img {
  width: 35px;
}

.floorplans_section {
  padding: 80px 0;
}

.floorplan_item_popup a {
  text-transform: uppercase;
  color: #7a7a7a;
  letter-spacing: 1px;
  font-size: 14px;
  display: block;
  padding: 7px 50px;
}
.floorplan_item_popup a:hover {
  background: #bf9643;
  color: #fff;
}

body.page-id-61 .section_1 .two_column_content_image_inner,
body.page-id-61 .section_1 .two_column_content_section {
  margin-top: 0;
}

.two_column_content_image_col_gallery {
  height: 100%;
}
.two_column_content_image_col_gallery .carousel,
.two_column_content_image_col_gallery .carousel-inner,
.two_column_content_image_col_gallery .carousel-item {
  height: 100%;
}

body.page-id-61 .section_1 {
  padding-top: 70px;
  padding-bottom: 100px;
}
body.page-id-61 .section_1 .two_column_content_inner {
  background: #fff;
}

.two_column_content_text_col_content ul li {
  margin-bottom: 7px;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
}

.gallery_section {
  padding: 80px 0;
  padding-top: 250px;
}
.gallery_section ul {
  display: flex;
  flex-wrap: wrap;
}
.gallery_section ul li {
  width: 50%;
  height: 40vw;
  border-right: 40px solid #fff;
  border-bottom: 40px solid #fff;
}
.gallery_section ul li:first-child {
  border-left: 40px solid #fff;
}
.gallery_section ul li:last-child {
  border-right: 40px solid #fff;
}
.gallery_section ul li:nth-child(even) {
  margin-top: -170px;
}

body.page-id-67 .simple_text_inner p a {
  background: #bf9643;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 25px;
  min-width: 176px;
  text-align: center;
  margin: 0 10px;
  margin-bottom: 30px;
}
body.page-id-67 .simple_text_inner p a:hover {
  background: #958d7c;
}

.contact_form_contact {
  padding-left: 30px;
}
.contact_form_contact h3 {
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 5px;
}

.contact_modal_social a {
  color: #7a7a7a;
  margin-right: 5px;
}

#map {
  height: 700px;
}

.schedule_tour_form .contact_field input,
.schedule_tour_form .contact_field select,
.schedule_tour_form .contact_field textarea {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.schedule_tour_form .contact_field input::placeholder {
  color: #fff;
  opacity: 1;
}
.schedule_tour_form .contact_field.contact_field_submit {
  @apply lg:text-center mb-0;
}
.schedule_tour_form .contact_field.contact_field_submit input {
  @apply bg-accent border w-full lg:w-fit px-8 border-light tracking-wider text-lg uppercase hover:bg-white hover:text-accent transition-all duration-300 hover:border-accent inline-block mt-12;
}

@media all and (max-width: 767px) {
  .header_menu {
    margin-top: -29px;
  }
  .header_menu ul li {
    display: none;
  }
  .header_menu ul li:last-child {
    display: block;
  }
  .top_bar {
    padding: 20px 0;
  }
  .header_logo {
    text-align: left;
  }
  .home_header_action_btn {
    display: none;
  }
  .two_column_content_text_col_content {
    text-align: left !important;
  }
  .parallax_section_content {
    padding: 15px;
  }
  .parallax_section_content p {
    font-size: 14px;
  }
  .parallax_section_content h2 {
    font-size: 32px;
  }
  .parallax_section {
    padding-top: 100px;
  }
  .contact_gallery_section button.owl-prev {
    margin-right: 60px;
  }
  .contact_gallery_section button.owl-next {
    margin-left: 60px;
  }
  .footer_contact_col {
    padding: 0;
    text-align: center;
  }
  .header_modal_contact {
    padding: 0;
    text-align: center;
    display: none;
  }
  body.page-id-61 .section_1 {
    padding-top: 0;
  }
  .map_section h2 {
    font-size: 32px;
  }
  div#map {
    height: 400px;
  }
  .neighborhoods_section .col-md-6:nth-child(odd) .neighborhood_item {
    margin-top: 0;
  }
  .neighborhood_item_details {
    padding: 20px 20px;
  }
  .neighborhood_item_details h3 {
    font-size: 20px;
  }
  .walk_score_section {
    padding: 40px 0;
  }
  .walk_score_section_image {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .gallery_section {
    padding: 40px 15px;
  }
  .gallery_section ul {
    display: block;
  }
  .gallery_section ul li {
    border: 0 !important;
    width: 100%;
    margin-bottom: 20px;
    display: block;
    height: 220px;
    margin-top: 0 !important;
  }
  .contact_form_contact {
    padding-left: 0;
    margin-top: 20px;
  }
}
.ui-widget.ui-widget-content {
  border: 0;
  margin: 0 auto;
  background: transparent;
  color: #fff;
  width: auto;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button {
  @apply border-0 text-center m-[5px] text-white bg-white/20 aspect-square flex items-center justify-center;
}
.ui-state-default:hover,
.ui-widget-content .ui-state-default:hover,
.ui-widget-header .ui-state-default:hover,
.ui-button:hover {
  @apply bg-white/50;
}

html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  background: transparent;
  border: 1px solid rgba(216, 172, 101, 0.5);
  margin: 5px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  padding: 4px 3px;
}

.ui-datepicker td a {
  color: #fff;
}
.ui-datepicker .ui-datepicker-header {
  background: transparent;
  border: none;
  color: #fff;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 4px;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  @apply bg-white text-accent;
}

.ui-datepicker th {
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;
}

.tourdatefield {
  @apply hidden;
}

.ui-datepicker-header a,
.ui-datepicker-header a:hover,
.ui-datepicker-header a.ui-datepicker-next,
.ui-datepicker-header a.ui-datepicker-prev,
.ui-datepicker-header a.ui-state-hover {
  @apply block w-5 h-5 border border-transparent bg-transparent;
}
.ui-datepicker-header span {
  @apply bg-center bg-no-repeat bg-contain;
}
.ui-datepicker-header .ui-datepicker-prev span {
  @apply rotate-180;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='29' viewBox='0 0 17 29'%3E%3Cpath id='Polígono_1' data-name='Polígono 1' d='M14.5,0,29,17H0Z' transform='translate(17) rotate(90)' fill='%23fff'/%3E%3C/svg%3E%0A");
}
.ui-datepicker-header .ui-datepicker-next span {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.483' height='26.412' viewBox='0 0 15.483 26.412'%3E%3Cpath id='Polígono_1' data-name='Polígono 1' d='M13.206,0,26.412,15.483H0Z' transform='translate(15.483) rotate(90)' fill='%23fff'/%3E%3C/svg%3E%0A");
}